import swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);
//import MobileDetect from 'mobile-detect'

//const md = new MobileDetect(window.navigator.userAgent)


// Stories
if ($('body .stories').length) {
    var isForward = false

    var intersectionObserver = new IntersectionObserver(function(entries) {
      
        entries.forEach(entry => {
            if (entry.isIntersecting) {
              let elem = entry.target;

              console.log(entry)
        
            //   if (entry.intersectionRatio >= 0 && entry.intersectionRatio < 0 && !isForward) {
            //     setTimeout(() => {
            //         isForward = true
            //         animationStories('forward')
            //     }, 500)
            //   }
            //   if (entry.intersectionRatio < 0.70 && isForward) {
            //     setTimeout(() => {
            //         isForward = false
            //         animationStories('reverse')
            //     }, 500)
            //   }

            if (entry.boundingClientRect.top < 500 && !isForward) {
                console.log('less than 0')
                setTimeout(() => {
                    isForward = true
                    animationStories('forward')
                }, 2500)
              }
              if (entry.boundingClientRect.top > 500 && isForward) {
                console.log('less than 0')
                setTimeout(() => {
                    isForward = false
                    animationStories('reverse')
                }, 0)
              }
            }
          });


      }, {threshold: [0, 0.25, 0.5, 0.75, 1.0]});
    
      intersectionObserver.observe(document.querySelector('#stories'));



    //   var testObserver = new IntersectionObserver(function(entries) {
    //     // If intersectionRatio is 0, the target is out of view
    //     // and we do not need to do anything.
    //     entries.forEach(entry => {
    //         if (entry.isIntersecting) {
    //           let elem = entry.target;

    //           console.log(entry)
        
    //           if (entry.intersectionRatio >= 0.75 && entry.intersectionRatio < 1.0 && !isForward) {
    //             setTimeout(() => {
    //                 isForward = true
    //                 animationStories('forward')
    //             }, 500)
    //           }
    //           if (entry.intersectionRatio < 0.75 && isForward) {
    //             setTimeout(() => {
    //                 isForward = false
    //                 animationStories('reverse')
    //             }, 500)
    //           }
    //         }
    //       });


    //   }, {threshold: [0, 0.1, 0.1, 0.3, 0.4, 0.5, 0.6, 0.75, 1.0]});
    //   // start observing
    //   intersectionObserver.observe(document.querySelector('.title .heading-1'));


    function animationStories(direction) {
        if (direction === 'forward') {
            console.log('forward')
            $('.stories .title').animate({
                opacity: '0',
            }, 0)
            $('.stories .title').css({
                transform: 'translateY(-10%)',
            }, 0)
            $('.stories .banner-stage-1').css({
                opacity: '0'
            })
            $('.stories .content').css({
                visibility: 'visible',
                opacity: '1'
            })
        }
        if (direction === 'reverse') {
            console.log('reverse')
            $('.stories .title').animate({
                opacity: '1',
            }, 0)
            $('.stories .title').css({
                transform: 'translateY(0%)',
            }, 0)
            $('.stories .banner-stage-1').css({
                opacity: '1'
            })
            $('.stories .content').css({
                visibility: 'hidden',
                opacity: '0'
            })
        }
    }

    function removeAllInfo() {
        $('.stories .marker').each((index, obj) => {
            $(obj).children('.marker-info').removeClass('visible')
        })
    }


    $('.stories .marker').each((index, obj) => {
        $(obj).on('click', () => {
            new Promise((resolve, reject) => {
                removeAllInfo()
                resolve()
            })
            .then(() => {
                $(obj).children('.marker-info').toggleClass('visible')
            })
            .catch((error) => {
                console.log(error)
            })
        })
        $(obj).on('mouseleave', () => {
            removeAllInfo()
        })
    })

}

// Mobile slider
// if (md.mobile() || $(window).width() <= 750) {
    var feature_slider = new swiper('.feature-slider-container', {
        direction: 'horizontal',
        autoHeight: false,
        observer: true,
        observeParents: true,
        loop: false,
        watchOverflow: true,
        autoplay: {
          delay: 7000,
        },
        pagination: {
          el: '.feature-slider-controls',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">0' + (index + 1) + '</span>';
          },
        },
    });
    var wine_image_slider = new swiper('.image-slider-container', {
        direction: 'horizontal',
        autoHeight: true,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 4000,
        },
    });
    
    var storiesSlider = new swiper ('.stories-slider-container', {
        direction: 'horizontal',
        loop: true,
        hashNavigation: true,
        autoHeight: true,
        speed: 600,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
          },
        navigation: {
          nextEl: '.stories-button-next',
          prevEl: '.stories-button-prev',
        },
        })
        $('a[data-slide]').click(function(e){
            e.preventDefault();
            storiesSlider.slideTo( $(this).data('slide') );
    })
 
    // storiesSlider.on('transitionStart', function () {
    //     $('.marker').each((index, obj) => {
    //         $(obj).first('img').removeClass('pin-active')
    //     })
    //     $('[data-pin-index=' + (storiesSlider.realIndex + 1) + ']').addClass('pin-active')
    // });

    // $('.marker img').each((index, obj) => {
    //     $(obj).on('click', (e) => {
    //         e.preventDefault()
    //         var $index = parseInt($(obj).parent('.marker').attr('data-pin-index')) -1
    //         console.log($index)
    //         storiesSlider.slideToLoop($index, 600)
    //     })
    // })


// }