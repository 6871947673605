if (navigator.userAgent.match(/android/i)) {
	var viewport1 = document.querySelector("meta[name=viewport]");
	viewport1.setAttribute('content', 'width=device-width, initial-scale=0.5, user-scalable=0, width=720');
}
if (navigator.userAgent.match(/ipad/i)) {
	var viewport2 = document.querySelector("meta[name=viewport]");
	viewport2.setAttribute('content', 'width=device-width, initial-scale=0.60, user-scalable=0');
}
if (navigator.userAgent.match(/nexus/i)) {
	var viewport3 = document.querySelector("meta[name=viewport]");
	viewport3.setAttribute('content', 'width=device-width, initial-scale=0.77, user-scalable=0, width=720');
}
if (navigator.userAgent.match(/Silk/)) {
	var viewport4 = document.querySelector("meta[name=viewport]");
	viewport4.setAttribute('content', 'width=device-width, initial-scale=1.59, user-scalable=0');
}
if (navigator.userAgent.match(/iPhone/i)) {
	var viewport5 = document.querySelector("meta[name=viewport]");
	viewport5.setAttribute('content', 'width=device-width, initial-scale=0.5, user-scalable=0, width=640');
}
if (navigator.userAgent.match(/BB10/)) {
	var viewport6 = document.querySelector("meta[name=viewport]");
	viewport6.setAttribute('content', 'width=device-width, initial-scale=0.77, user-scalable=0, width=360');
}
if (navigator.userAgent.match(/BB30/)) {
	var viewport7 = document.querySelector("meta[name=viewport]");
	viewport7.setAttribute('content', 'width=device-width, initial-scale=0.77, user-scalable=0, width=360');
}
if ($(window).width() > 749) {
	if (navigator.userAgent.match(/iPhone/i)) {
		var viewport8 = document.querySelector("meta[name=viewport]");
		viewport8.setAttribute('content', 'width=device-width, initial-scale=0.595, user-scalable=0');
	}
}
if ($(window).width() > 749) {
	if (navigator.userAgent.match(/android/i)) {
		var viewport9 = document.querySelector("meta[name=viewport]");
		viewport9.setAttribute('content', 'width=device-width, initial-scale=0.58, user-scalable=0, width=750');
	}
}
if (navigator.userAgent.match(/Windows Phone/i)) {
	var viewport10 = document.querySelector("meta[name=viewport]");
	viewport10.setAttribute('content', 'width=device-width, initial-scale=0.77, user-scalable=0, width=750');
}