// import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import 'foundation-sites/js/foundation'

// import JS modules to compile
import './init-foundation'
import './wp-foundation'
import './modules/fancybox'
import './modules/store-locator'
import './modules/navMenu'
//import './modules/carousel'
//import './modules/sliders'
import './modules/contentScroll'
import './modules/stories'
import './modules/label-maker'
import './modules/ie'
import './modules/viewports'
import './modules/animations'
//import './modules/select-fields'
// import './modules/select'
// import './modules/accordion'
// import './modules/scrollSlider'
// import './modules/modules'
// import './modules/gallery'
// import './modules/wineDetail'
// import './modules/search'
// import './modules/submenu'
//import './modules/contact'