import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)

$(document).ready((e) => {
        // Home
        if ($('body .home').length) {
            var contentController = new ScrollMagic.Controller();

            if (!md.mobile() && $(window).width() >= 1024) { 
                var cameraAnimation = new TimelineMax()
                    .to('.camera-1 img', 1, {css: {transform: 'translateX(-20%)'}})
            } else {
                var cameraAnimation = new TimelineMax()
                    .to('.camera-1 img', 1, {css: {transform: 'translateX(10%)'}})
            }

            var scene = new ScrollMagic.Scene({triggerElement: ".copy-top", triggerHook: 'onEnter', duration: '100%', offset: 100})
                .setTween(cameraAnimation)
                .addTo(contentController)

            if (!md.mobile() && $(window).width() >= 1024) { 
                var cameraAnimation2 = new TimelineMax()
                    .to('.camera-2 img', 1, {css: {transform: 'translateX(20%)'}})
            } else {
                var cameraAnimation2 = new TimelineMax()
                .to('.camera-2 img', 1, {css: {transform: 'translateX(-10%)'}})
            }

            var scene = new ScrollMagic.Scene({triggerElement: ".copy-middle", triggerHook: 'onEnter', duration: '100%', offset: 100})
                .setTween(cameraAnimation2)
                .addTo(contentController)
        }

        // About
        if ($('body .about').length) {
            var contentController = new ScrollMagic.Controller();

            // Building
            if (!md.mobile() && $(window).width() >= 640) { 
                var cameraAnimationBuilding = new TimelineMax()
                    .to('.about-building .camera-1 img', 1, {css: {transform: 'translateX(20%)'}})
            } else {
                var cameraAnimationBuilding = new TimelineMax()
                    .to('.about-building .camera-1 img', 1, {css: {transform: 'translateX(-10%)'}})
            }

            var scene = new ScrollMagic.Scene({triggerElement: ".about-building .copy-top", triggerHook: 'onEnter', duration: '100%', offset: 100})
                .setTween(cameraAnimationBuilding)
                .addTo(contentController)
       
            if (!md.mobile() && $(window).width() >= 640) { 
                var cameraAnimationBuilding2 = new TimelineMax()
                    .to('.about-building .camera-2 img', 1, {css: {transform: 'translateX(-20%)'}})
            } else {
                var cameraAnimationBuilding2 = new TimelineMax()
                    .to('.about-building .camera-2 img', 1, {css: {transform: 'translateX(10%)'}})
            }

            var scene = new ScrollMagic.Scene({triggerElement: ".copy-middle", triggerHook: 'onEnter', duration: '100%', offset: 100})
                .setTween(cameraAnimationBuilding2)
                .addTo(contentController)

            // Family
            if (!md.mobile() && $(window).width() >= 640) { 
                var cameraAnimationFamily = new TimelineMax()
                    .to('.about-family .camera-1 img', 1, {css: {transform: 'translateX(-20%)'}})
            } else {
                var cameraAnimationFamily = new TimelineMax()
                    .to('.about-family .camera-1 img', 1, {css: {transform: 'translateX(10%)'}})
            }

            var scene = new ScrollMagic.Scene({triggerElement: ".about-family .copy-top", triggerHook: 'onEnter', duration: '100%', offset: 100})
                .setTween(cameraAnimationFamily)
                .addTo(contentController)


            if (!md.mobile() && $(window).width() >= 640) { 
                var cameraAnimationFamily2 = new TimelineMax()
                    .to('.about-family .camera-2 img', 1, {css: {transform: 'translateX(20%)'}})
            } else {
                var cameraAnimationFamily2 = new TimelineMax()
                    .to('.about-family .camera-2 img', 1, {css: {transform: 'translateX(-20%)'}})
            }

            var scene = new ScrollMagic.Scene({triggerElement: ".about-family .copy-middle", triggerHook: 'onEnter', duration: '100%', offset: 100})
                .setTween(cameraAnimationFamily2)
                .addTo(contentController)
        }
})

