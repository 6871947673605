import fancybox from '@fancyapps/fancybox'

$(document).ready(function() {
    $('[data-fancybox]').fancybox({
        vimeo : {
            color : 'f00'
        }
    });
});


