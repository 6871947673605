import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)

var isScrolledIntoView = function(elem) {
    var elemTop = $(elem).offset().top,
        elemHeight = $(elem).height()
    var docViewBottom = $(window).scrollTop() + $(window).height()

    return ((elemTop + elemHeight) >= $(window).scrollTop() && elemTop < docViewBottom);
}

var isOutOfViewport = function (elem) {
    var elem = document.querySelector(elem);
	// Get element's bounding
	var bounding = elem.getBoundingClientRect();

	// Check if it's out of the viewport on each side
	var out = {};
	out.top = bounding.top < 0;
	out.left = bounding.left < 0;
	out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
	out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
	out.any = out.top || out.left || out.bottom || out.right;
	out.all = out.top && out.left && out.bottom && out.right;

	return out;
};


// HOME
if ($('body .home').length) {
    var isForward = false,
        $window = window
    var contentController = new ScrollMagic.Controller();
        

    var intersectionObserver = new IntersectionObserver(function(entries) {
        // If intersectionRatio is 0, the target is out of view
        // and we do not need to do anything.
        entries.forEach(entry => {
            if (entry.isIntersecting) {
              let elem = entry.target;

              if (!md.mobile() && $(window).width() >= 640) {

              }
              else {

              }
        
              if (entry.intersectionRatio >= 0.50 && entry.intersectionRatio < 1.0 && !isForward) {
                setTimeout(() => {
                    isForward = true
                    animationStories('forward');
                }, 500)
              }
              if (entry.intersectionRatio < 0.50 && isForward) {
                setTimeout(() => {
                    isForward = false
                    animationStories('reverse');
                }, 500)
              }
            }
          });


      }, {threshold: [0, 0.5, 1.0]});
      // start observing
      intersectionObserver.observe(document.querySelector('#our-wines'));


    function animationStories(direction) {
        if (direction === 'forward') {
            console.log('forward');
            $('.our-wines .content').css({
                visibility: 'visible',
                opacity: '1',
                transform: 'translateY(0%)',
            })
        }
        if (direction === 'reverse') {
            console.log('reverse');
            $('.our-wines .content').css({
                visibility: 'hidden',
                opacity: '0',
                transform: 'translateY(-10%)',
            })
        }
    }

}



// About
if ($('body .about').length) {
    var isForward = false,
        $window = window
    var contentController = new ScrollMagic.Controller();
        
    var intersectionObserver = new IntersectionObserver(function(entries) {
        // If intersectionRatio is 0, the target is out of view
        // and we do not need to do anything.
        entries.forEach(entry => {
            if (entry.isIntersecting) {
              let elem = entry.target;

              console.log(entry)

              if (md.mobile() || $(window).width() <= 640) {
                if (entry.boundingClientRect.top < 0 && !isForward) {
                    console.log('less than 0')
                    setTimeout(() => {
                        isForward = true
                        animationStories2('forward')
                    }, 0)
                  }
                  if (entry.boundingClientRect.top > 0 && isForward) {
                    console.log('less than 0');
                    setTimeout(() => {
                        isForward = false
                        animationStories2('reverse');
                    }, 0)
                  }
              }
              else {
                if (entry.boundingClientRect.top < 500 && !isForward) {
                    console.log('less than 0');
                    setTimeout(() => {
                        isForward = true
                        animationStories2('forward');
                    }, 2500)
                  }
                  if (entry.boundingClientRect.top > 500 && isForward) {
                    console.log('less than 0');
                    setTimeout(() => {
                        isForward = false
                        animationStories2('reverse');
                    }, 0)
                  }
              }
        
            }
            if (!entry.isIntersecting) {
                setTimeout(() => {
                    isForward = false
                    animationStories2('reverse');
                }, 0)
              }

          });
      }, {threshold: [0, 0.5, 0.15, 0.25, 0.35, 0.45, 0.5, 0.65, 0.75, 0.85, 0.95, 1.0]});
      // start observing
      intersectionObserver.observe(document.querySelector('#description'));


    function animationStories2(direction) {
        if (direction === 'forward') {
            console.log('is forward');
            $('.description .title').animate({
                opacity: '0',
            }, 0)
            $('.description .title').css({
                transform: 'translateY(0%)',
            }, 0)
            $('.description .content').css({
                visibility: 'visible',
                opacity: '1'
            })
        }
        if (direction === 'reverse') {
            console.log('is reverse');
            $('.description .title').animate({
                opacity: '1',
            }, 0)
            $('.description .title').css({
                transform: 'translateY(0%)',
            }, 0)
            $('.description .content').css({
                visibility: 'hidden',
                opacity: '0'
            })
        }
    }

}

       
    if ($('.wpcf7-form').length) {
        var canada = {
            alberta: {
                name: 'Alberta',
                abbreviation: 'AB'
            },
            britishColumbia: {
                name: 'British Columbia',
                abbreviation: 'BC'
            },
            manitoba: {
                name: 'Manitoba',
                abbreviation: 'MB'
            },
            newBrunswick: {
                name: 'New Brunswick',
                abbreviation: 'NB'
            },
            newfoundlandAndLabrador: {
                name: 'Newfoundland and Labrator',
                abbreviation: 'NL'
            },
            northwestTerritories: {
                name: 'Northwest Territories',
                abbreviation: 'NT'
            },
            novaScotia: {
                name: 'Nova Scotia',
                abbreviation: 'NS'
            },
            nunavut: {
                name: 'Nunavut',
                abbreviation: 'NU'
            },
            ontario: {
                name: 'Ontario',
                abbreviation: 'ON'
            },
            princeEdwardIsland: {
                name: 'Prince Edward Island',
                abbreviation: 'PE'
            },
            quebec: {
                name: 'Quebec',
                abbreviation: 'QC'
            },
            saskatchewan: {
                name: 'Saskatchewan',
                abbreviation: 'SK'
            },
            yukon: {
                name: 'Yukon',
                abbreviation: 'YT'
            },
        }

        var unitedStates = {
            alabama: {
                name: 'Alabama',
                abbreviation: 'AL'
            },
            alaska: {
                name: 'Alaska',
                abbreviation: 'AK'
            },
            americonSamoa: {
                name: 'American Samoa',
                abbreviation: 'AS'
            },
            arizona: {
                name: 'Arizona',
                abbreviation: 'AZ'
            },
            arkansas: {
                name: 'Arkansas',
                abbreviation: 'AR'
            },
            california: {
                name: 'California',
                abbreviation: 'CA'
            },
            colorado: {
                name: 'Colorado',
                abbreviation: 'CO'
            },
            connecticut: {
                name: 'Connecticut',
                abbreviation: 'CT'
            },
            deleware: {
                name: 'Delaware',
                abbreviation: 'DE'
            },
            districtOfColumbia: {
                name: 'District of Columbia',
                abbreviation: 'DC'
            },
            federatedStatesOfMicronesia: {
                name: 'Federated States of Micronesia',
                abbreviation: 'FM'
            },
            florida: {
                name: 'Florida',
                abbreviation: 'FL'
            },
            georgia: {
                name: 'Georgia',
                abbreviation: 'GA'
            },
            guam: {
                name: 'Guam',
                abbreviation: 'GU'
            },
            hawaii: {
                name: 'Hawaii',
                abbreviation: 'HI'
            },
            idaho: {
                name: 'Idaho',
                abbreviation: 'ID'
            },
            illinios: {
                name: 'Illinois',
                abbreviation: 'IL'
            },
            indiana: {
                name: 'Indiana',
                abbreviation: 'IN'
            },
            iowa: {
                name: 'Iowa',
                abbreviation: 'IA'
            },
            kansas: {
                name: 'Kansas',
                abbreviation: 'KS'
            },
            kentucky: {
                name: 'Kentucky',
                abbreviation: 'KY'
            },
            louisiana: {
                name: 'Louisiana',
                abbreviation: 'LA'
            },
            maine: {
                name: 'Maine',
                abbreviation: 'ME'
            },
            marshallIslands: {
                name: 'Marshall Islands',
                abbreviation: 'MH'
            },
            maryland: {
                name: 'Maryland',
                abbreviation: 'MD'
            },
            massachusetts: {
                name: 'Massachusetts',
                abbreviation: 'MA'
            },
            michigan: {
                name: 'Michigan',
                abbreviation: 'MI'
            },
            minnesota: {
                name: 'Minnesota',
                abbreviation: 'MN'
            },
            mississippi: {
                name: 'Mississippi',
                abbreviation: 'MS'
            },
            missouri: {
                name: 'Missouri',
                abbreviation: 'MO'
            },
            montana: {
                name: 'Montana',
                abbreviation: 'MT'
            },
            nebraska: {
                name: 'Nebraska',
                abbreviation: 'NE'
            },
            newHampshire: {
                name: 'New Hampshire',
                abbreviation: 'NH'
            },
            newJersey: {
                name: 'New Jersey',
                abbreviation: 'NJ'
            },
            newMexico: {
                name: 'New Mexico',
                abbreviation: 'NM'
            },
            newYork: {
                name: 'New York',
                abbreviation: 'NY'
            },
            northCarolina: {
                name: 'North Carolina',
                abbreviation: 'NC'
            },
            northDakota: {
                name: 'North Dakota',
                abbreviation: 'ND'
            },
            northernMarianaIslands: {
                name: 'North Mariana Islands',
                abbreviation: 'MP'
            },
            ohio: {
                name: 'Ohio',
                abbreviation: 'OH'
            },
            oklahoma: {
                name: 'Oklahoma',
                abbreviation: 'OK'
            },
            oregon: {
                name: 'Oregon',
                abbreviation: 'OR'
            },
            palau: {
                name: 'Palau',
                abbreviation: 'PW'
            },
            pennsylvania: {
                name: 'Pennsylvania',
                abbreviation: 'PA'
            },
            puertoRico: {
                name: 'Puerto Rico',
                abbreviation: 'PR'
            },
            rhodeIsland: {
                name: 'Rhode Island',
                abbreviation: 'RI'
            },
            southCarolina: {
                name: 'South Carolina',
                abbreviation: 'SC'
            },
            southDakota: {
                name: 'South Dakota',
                abbreviation: 'SD'
            },
            tennessee: {
                name: 'Tennessee',
                abbreviation: 'TN'
            },
            texas: {
                name: 'Texas',
                abbreviation: 'TX'
            },
            utah: {
                name: 'Utah',
                abbreviation: 'UT'
            },
            vermont: {
                name: 'Vermont',
                abbreviation: 'VT'
            },
            virginIslands: {
                name: 'Virgin Islands',
                abbreviation: 'VI'
            },
            virginia: {
                name: 'Virginia',
                abbreviation: 'VA'
            },
            washington: {
                name: 'Washington',
                abbreviation: 'WA'
            },
            westVirginia: {
                name: 'West Virginia',
                abbreviation: 'WV'
            },
            wisconsin: {
                name: 'Wisconsin',
                abbreviation: 'WI'
            },
            wyoming: {
                name: 'Wyoming',
                abbreviation: 'WY'
            },
        }


        function getStates() {
            // Check initial country Value
            if ($('#country').val() == 'US') {
                Object.values(unitedStates).forEach(state => {
                    $('#state').append('<option value="' + state.abbreviation + '">' + state.name + '</option>');
                });
            }
            if ($('#country').val() == 'Canada') {
                Object.values(canada).forEach(state => {
                    $('#state').append('<option value="' + state.abbreviation + '">' + state.name + '</option>');
                });
            }
        }

        // Initiate Country check
        getStates();

        // Clear all state option fields
        function refreshStates() {
            $('#state option').each((index, obj) => {
                $(obj).remove();
            });
        }


        // Watch for country select change
        $('#country').on('change', (e) => {
            e.preventDefault();
            refreshStates();
            getStates();
        });

    }
    
    // if ($('.page-template-page-template-varietal .varietal').length) {
    //     var isForward = false,
    //     $window = window;
    //     var contentController = new ScrollMagic.Controller();
            
    //     var intersectionObserver = new IntersectionObserver(function(entries) {
    //         // If intersectionRatio is 0, the target is out of view
    //         // and we do not need to do anything.
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 let elem = entry.target;
    
    //                 console.log(entry);
    
    //                 if (md.mobile() || $(window).width() <= 640) {
    //                     if (entry.boundingClientRect.top < 0 && !isForward) {
    //                         console.log('less than 0');
    //                         setTimeout(() => {
    //                             isForward = true;
    //                             animationStories3('forward');
    //                         }, 0);
    //                       }
    //                       if (entry.boundingClientRect.top > 0 && isForward) {
    //                         console.log('less than 0');
    //                         setTimeout(() => {
    //                             isForward = false;
    //                             animationStories3('reverse');
    //                         }, 0);
    //                       }
    //                   }
    //                   else {
    //                     if (entry.boundingClientRect.top < 500 && !isForward) {
    //                         console.log('less than 0');
    //                         setTimeout(() => {
    //                             isForward = true;
    //                             animationStories3('forward');
    //                         }, 2500);
    //                       }
    //                       if (entry.boundingClientRect.top > 500 && isForward) {
    //                         console.log('less than 0');
    //                         setTimeout(() => {
    //                             isForward = false;
    //                             animationStories3('reverse');
    //                         }, 0);
    //                       }
    //                   }
                
    //                 }
    //                 if (!entry.isIntersecting) {
    //                     setTimeout(() => {
    //                         isForward = false;
    //                         animationStories3('reverse');
    //                     }, 0);
    //                   }
        
    //               });
    //           }, {threshold: [0, 0.5, 0.15, 0.25, 0.35, 0.45, 0.5, 0.65, 0.75, 0.85, 0.95, 1.0]});
        
    //       intersectionObserver.observe(document.querySelector('#description'));
    
    
        // function animationStories3(direction) {
        //     if (direction === 'forward') {
        //         console.log('is forward');
        //         $('#description .title').animate({
        //             opacity: '0',
        //         }, 0);
        //         $('#description .title').css({
        //             transform: 'translateY(0%)',
        //         }, 0);
        //         $('#description .content').css({
        //             visibility: 'visible',
        //             opacity: '1'
        //         });
        //     }
        //     if (direction === 'reverse') {
        //         console.log('is reverse');
        //         $('#description .title').animate({
        //             opacity: '1',
        //         }, 0);
        //         $('#description .title').css({
        //             transform: 'translateY(0%)',
        //         }, 0);
        //         $('#description .content').css({
        //             visibility: 'hidden',
        //             opacity: '0'
        //         });
        //     }
    //      }
    
    // }
