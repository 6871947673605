// Nav Menu

// $(document).ready(() => {
//     $('#menu-item-19').on('click', (e) => {
//         e.preventDefault()
//         $('#example-animated-menu').css('display', 'none')
//         scrollToElement('#where-to-buy')
//     })
// })


// function scrollToElement( target ) {
//     var topoffset = 30
//     var speed = 100
//     var destination = $( target ).offset().top - topoffset
//     $('html:not(:animated),body:not(:animated)').animate( { scrollTop: destination}, speed, function() {
//         window.location.hash = target
//     })
//     return false
// }


$(document).ready(() => {
    $('.tribute-toggle').on('click', (e) => {
        e.preventDefault()

        $('.toggle-open').fadeToggle(0)
        $('.toggle-close').fadeToggle(0)
    
        // new Promise((resolve, reject) => {
        //     $('.toggle-open').fadeToggle(800)
        //     resolve()
        // })
        // .then(() => {
        //     $('.toggle-close').fadeToggle(800)
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
    })
    // if ($(window).width() > 1024) {
    //     $('.menu-item-16 > ul').hide();
    //     $('.menu-item-16').hover(function() {
    //         $('.menu-item-16 > ul').stop().fadeToggle();
    //         return false;
    //     });
    //     $('.menu-item-3815 > ul').hide();
    //     $('.menu-item-3815').hover(function() {
    //         $('.menu-item-3815 > ul').stop().fadeToggle();
    //         return false;
    //     });
    //     $('.menu-item-3763 > ul').hide();
    //     $('.menu-item-3763').hover(function() {
    //         $('.menu-item-3763 > ul').stop().fadeToggle();
    //         return false;
    //     });
    // }
})